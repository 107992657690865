import { PageProps, graphql } from 'gatsby';
import React from 'react';

import BaseLayout from '../layouts/base';
import { rhythm } from '../utils/typography';

import { BlogIndexQuery } from '../../types/graphql-types';
import styled from 'styled-components';

const BlogIndexPostContainer = styled.a`
  display: block;
  margin: auto;
  margin-bottom: ${rhythm(1)};
  padding: ${rhythm(1)};
  border-radius: ${rhythm(1 / 4)};
  box-shadow: 0 0 ${rhythm(1 / 4)} rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  text-decoration: none;
  &:hover {
    transform: scale(1.02);
    color: unset;
  }
  header {
    margin-bottom: ${rhythm(1 / 4)};
  }
  p {
    margin-bottom: 0;
  }
  header > h3 {
    margin-bottom: 0;
  }
`;

const BlogIndexPage: React.FC<PageProps<BlogIndexQuery>> = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <BaseLayout title="All posts">
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        const content = node.frontmatter.description || node.excerpt;
        return (
          <BlogIndexPostContainer
            href={node.fields.slug}
            key={node.fields.slug}
            data-sal="slide-up"
            data-sal-delay="0"
            data-sal-easing="ease"
          >
            <article>
              <header>
                <h3>{title}</h3>
                <small>{node.frontmatter.date}</small>
              </header>
              <section>
                <p dangerouslySetInnerHTML={{ __html: content }} />
              </section>
            </article>
          </BlogIndexPostContainer>
        );
      })}
    </BaseLayout>
  );
};

export default BlogIndexPage;

export const pageQuery = graphql`
  query BlogIndex {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
